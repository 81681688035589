<script>
import { MULTIPLIER, getCurrencyFormatter, getCurrencyFormatterBelowZero } from '~/helpers.js';

export default {
    props: {
        tonValue: {
            type: [Number, String],
            required: true,
            default: 0,
        },
    },

    render(createElement) {
        const fiatValue = (this.$props.tonValue / MULTIPLIER) * this.$store.state.exchangeRate;
        if (this.tonValue < 1000000000 && this.tonValue !== 0) {
            const formatter = getCurrencyFormatterBelowZero(this.$i18n.locale, this.$store.state.exchangeRateCurrency);

            return createElement('span', formatter.format(fiatValue));
        }
        const formatter = getCurrencyFormatter(this.$i18n.locale, this.$store.state.exchangeRateCurrency);

        return createElement('span', formatter.format(fiatValue));
    },
};
</script>
