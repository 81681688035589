<template>
    <section>
        <div class="chart-pie" style="height: 100%;">
            <canvas class="chart-pie__canvas" ref="canvas" width="200" height="200"/>

            <section class="chart-pie-inner" v-show="!dataset.isSkeleton">
                <header class="chart-pie-inner__counter">
                    <slot name="header"/>
                </header>
                <footer class="chart-pie-inner__explanation">
                    <slot name="footer"/>
                </footer>
            </section>

            <ui-chart-tooltip ref="tooltip" v-bind="tooltip"/>
        </div>

        <ui-chart-legend layout="table" v-bind:items="legend"/>
    </section>
</template>

<script>
import { loadChartJs } from '~/lib/Chart.js';
import { createTooltip, createTooltipStub } from './tooltip.js';
import ColorSchemeMixin from './mixins/changeColorScheme.js';
import UiChartTooltip from './UiChartTooltip.vue';
import UiChartLegend from './UiChartLegend.vue';

export default {
    chart: undefined,

    props: {
        labels: Array,
        dataset: {
            type: Object,
            default: () => ({
                data: [1],
                isSkeleton: true,
            }),
        },
    },

    watch: {
        dataset(newData) {
            loadChartJs().then(() => {
                this.$options.chart.config.options.animation.duration = 1000;
                this.$options.chart.data.labels = this.labels;
                this.$options.chart.data.datasets = [newData];
                this.$options.chart.update();
            });
        },
    },

    data() {
        return {
            legend: [],
            tooltip: {},
        };
    },

    beforeDestroy() {
        if (this.$options.chart) {
            this.chart.destroy();
        }

        this.$options.chart = undefined;
    },

    async mounted() {
        const Chart = await loadChartJs();

        const PieChart = new Chart(this.$refs.canvas, {
            type: 'doughnut',
            data: {
                labels: this.labels,
                datasets: [this.dataset],
            },
            options: {
                animation: { duration: 0 },
                cutout: '88%',
                borderWidth: 1.5, // 3
                borderRadius: 0, // 6
                borderColor: this.style.pieBorderColor,
                plugins: {
                    tooltip: createTooltip({
                        element: this.$refs.tooltip.$el,
                        onChange: newTooltip => Object.assign(this.tooltip, newTooltip),
                    }),

                    htmlLegend: {
                        onReady: props => this.$set(this, 'legend', props),
                    },
                },
            },
        });

        this.$options.chart = PieChart;
        this.tooltip = createTooltipStub(PieChart);
    },

    methods: {
        repaint() {
            this.$options.chart.options.borderColor = this.style.pieBorderColor;
            this.$options.chart.update();
        },
    },

    components: {
        UiChartLegend, UiChartTooltip,
    },

    mixins: [ColorSchemeMixin],
};
</script>

<style lang="scss">
.chart-pie {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100px;
    max-width: 280px;
    aspect-ratio: 1 / 1;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &__canvas {
       z-index: 2000;
       position: relative;
       color: var(--card-background);
       max-height: 100%;
    }
}
.chart-pie-inner {
    position: absolute;
    z-index: 1000;
    aspect-ratio: 1 / 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20%;
    border-radius: 50%;
    text-align: center;
    margin: auto;
    &__counter {
        font-size: 22px;
    }
    &__explanation {
        color: #adadab;
        margin-top: 4px;
        font-size: 14px;
    }
}
</style>
