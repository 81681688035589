<template>
    <div class="tx-history-wrap desktop-table">
        <table class="tx-table all-transactions-table">
            <thead v-if="!isMobile">
                <tr>
                    <th width="40"></th>
                    <th>
                        <div v-text="$t('common.time')" class="tx-table__cell"></div>
                    </th>
                    <th>
                        <div v-text="$t('address.all_tx_table.sender')" class="tx-table__cell tx-table__cell--align-right" style="padding-right: 0;"></div>
                    </th>
                    <th width="20"></th>
                    <th>
                        <div v-text="$t('address.all_tx_table.recipient')" class="tx-table__cell" style="padding-left: 0;"></div>
                    </th>
                    <th>
                        <div v-text="$t('address.tx_table.value')" class="tx-table__cell tx-table__cell--align-right"></div>
                    </th>
                </tr>
            </thead>

            <!-- Tables (desktop / mobile) -->
            <template v-if="transactions">
                <component
                    v-for="tx in transactions"
                    v-bind:key="tx.hash"
                    v-bind:is="isMobile ? 'blocks-tx-row-mobile' : 'blocks-tx-row'"
                    v-bind:hash="tx.hash"
                    v-bind:createdAt="tx.created_at"
                    v-bind:from="tx.from"
                    v-bind:to="tx.to"
                    v-bind:isService="tx.is_service"
                    v-bind:isExternal="tx.is_external"
                    v-bind:amount="tx.amount"
                />
            </template>

            <!-- Skeletons (desktop / mobile) -->
            <template v-else>
                <component
                    v-for="i in 40"
                    v-bind:is="isMobile ? 'tx-skeleton-mobile' : 'tx-skeleton'"
                    v-bind:key="`block_tx_skeleton_${i}`"/>
            </template>
        </table>

        <ui-mugen-scroll
            show-button
            v-bind:handler="loadOldTransactions"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:isLoading="isLoading"
        />
    </div>
</template>

<script>
import { getAllTransactions } from '~/api';
import TxSkeletonMobile from '~/components/address/TxRowSkeletonMobile.vue';
import TxSkeleton from './BlocksTransactionsRowSkeleton.vue';
import BlocksTxRow from './BlocksTransactionsRow.vue';
import BlocksTxRowMobile from './BlocksTransactionsRowMobile.vue';

export default {
    props: {
        workchain: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            transactions: null,
            isLoading: false,
            truncateTransactionList: true,
            timerHandle: null,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading;
        },
    },

    async created() {
        await this.fetchInitialTransactions();
        this.timerHandle = setInterval(this.loadNewTransactions, 6000);
    },

    beforeDestroy() {
        clearInterval(this.timerHandle);
    },

    methods: {
        async fetchInitialTransactions() {
            this.isLoading = true;
            try {
                this.transactions = await getAllTransactions({
                    wc: this.workchain,
                    limit: 10,
                });
            } catch (error) {
                console.log('Error fetching initial transactions:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadNewTransactions() {
            try {
                const lastTxTimestamp = this.transactions.length ? this.transactions[0].created_at + 1 : Date.now();
                const newTxs = await getAllTransactions({
                    wc: this.workchain,
                    startUtime: lastTxTimestamp,
                });

                const uniqueNewTxs = newTxs.filter(
                    newTx => !this.transactions.some(
                        tx => (
                            tx.created_at === newTx.created_at
                            && tx.amount === newTx.amount
                        ),
                    ),
                );

                this.transactions = [...uniqueNewTxs, ...this.transactions].sort((a, b) => b.created_at - a.created_at);

                if (this.truncateTransactionList) {
                    this.transactions.splice(32);
                }
            } catch (error) {
                console.log('Error loading new transactions:', error);
            }
        },

        async loadOldTransactions() {
            this.truncateTransactionList = false;
            this.isLoading = true;
            try {
                const firstTimestamp = this.transactions.length ? this.transactions[this.transactions.length - 1].created_at - 1 : Date.now();
                const oldTxs = await getAllTransactions({
                    wc: this.workchain,
                    endUtime: firstTimestamp,
                });

                this.transactions = [...this.transactions, ...oldTxs].sort((a, b) => b.created_at - a.created_at);
            } catch (error) {
                console.log('Error loading old transactions:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },

    components: {
        BlocksTxRow,
        BlocksTxRowMobile,
        TxSkeleton,
        TxSkeletonMobile,
    },
};
</script>

<style lang="scss">
.all-transactions-table {
    thead {
        background-color: var(--tx-table-thead-alt-background);
    }
}
</style>
