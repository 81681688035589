import axios from 'axios';
import { TONTECH_API_ENDPOINT } from '~/config.js';
import { parseCsv } from '~/utils.js';

const http = axios.create({
    baseURL: TONTECH_API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
    },
});

/**
 * @return {Promise<Object>}
 */
export const getStatus = function getBlockchainBasicMetricsHistory() {
    return http.get('/status').then(({ data }) => data);
};

/**
 * @return {Promise<Array>}
 */
export const getStakingInformation = async function getStakingYieldAndApyHistory() {
    const { data } = await http.get('/yield');

    return data.slice(-340).map(({ date, ...item }) => ({
        date: date.replace('Z', ''),
        ...item,
    }));
};

/**
 * @return {Promise<Object>}
 */
export const getSuspendedAddresses = function getSuspendedEarlyMinersList() {
    return http.get('/early_miners').then(({ data }) => parseCsv(data));
};
