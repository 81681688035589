<template>
    <section>
        <section v-if="isError">
            <div class="alert" v-text="$t('error.invalid_tx')" />
        </section>

        <status
            v-if="isSuccess !== undefined"
            v-bind:isSuccess="isSuccess"
            v-bind:computeExitCode="computeExitCode"
            v-bind:actionResultCode="actionResultCode"
            v-bind:address="address"
            v-bind:timestamp="timestamp"
            v-show="!isError"
        />

        <div v-show="!isError" class="card">
            <div class="card-title">
                <icon-trace class="card-title__icon icon-fixed" /> {{ $t('tx.series') }}
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.trace_id')" />
                <div class="card-row__value">
                    <ui-copy-button v-if="traceId" v-bind:copy="traceId"
                        v-bind:successMessage="$t('tx.hash_copy_success')">
                        {{ traceId }}
                    </ui-copy-button>
                    <span v-else class="skeleton">0000000000000</span>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row__name" v-text="$t('tx.timeStart')" />
                <div class="card-row__value">
                    <template v-if="timeStart">
                        {{ formattedDate }}
                        <span class="card-timeago">&mdash;</span>
                        <ui-timeago class="card-timeago" v-bind:timestamp="timeStart" />
                    </template>
                    <span v-else class="skeleton">00/00/2000 10:00</span>
                </div>
            </div>
        </div>

        <div v-show="!isError && trace" class="trace">
            <Diagram
                v-bind:trace="trace"
                v-bind:connections="connections"
                v-on:node-clicked="handleNodeClicked"
            />
        </div>

        <Transaction
            v-if="transactionData"
            v-bind:transactionData="transactionData"
        />

        <div class="transaction-button" v-if="showButton && !isLoading">
            <div class="transaction-button__items" @click="handleButtonClicked">
                <div class="transaction-button__icon">
                    <icon-tx-type-ordinary />
                </div>
                <div class="transaction-button__value">
                    <span>{{ $t('tx.button_transactions') }}</span>
                </div>
            </div>
        </div>

        <!-- <div v-show="!isError && !transactionData" class="card">
            <div class="card-row" style="border-bottom: none;">
                <div class="card-row__name" v-text="$t('tx.msgs')" />
                <div class="card-row__value">
                    <span v-if="isLoading" class="skeleton">1 input, 1 output</span>
                    <span v-else-if="!inMsg && !outMsgs.length" v-text="$t('tx.msgs_empty')" />
                    <span v-else v-text="$t('tx.msgs_count', [1, outMsgs.length])" />
                </div>
                <div class="card-row__chevron" v-on:click="toggleMsg">
                    <icon-expand v-bind:class="{ 'card-row__chevron-expand': msgOpen }" />
                </div>
            </div>
            <div class="tx-page-messages" v-show="msgOpen">
                <div v-if="inMsg" class="tx-page-msg">
                    <div class="card-row__name">
                        <span class="tx-table__badge tx-table__badge--in">IN</span>
                    </div>
                    <tx-msg class="tx-page-msg-details"
                        v-bind:source="addressBook[inMsg.source]?.user_friendly || null"
                        v-bind:destination="addressBook[inMsg.destination]?.user_friendly || null"
                        v-bind:value="inMsg.value"
                        v-bind:fwdFee="inMsg.fwd_fee"
                        v-bind:ihrFee="inMsg.ihr_fee"
                        v-bind:createdLt="inMsg.created_lt"
                        v-bind:hash="inMsg.hash"
                        v-bind:comment="inMsg.message_content?.decoded?.comment"
                        v-bind:op="inMsg.opcode" />
                </div>

                <div class="tx-page-msg" v-for="msg in outMsgs" v-bind:key="msg.hash">
                    <div class="card-row__name">
                        <span v-if="!msg.destination" class="tx-table__badge tx-table__badge--service"
                            v-text="$t('address.tx_table.log')" />

                        <span v-else class="tx-table__badge tx-table__badge--out" v-text="$t('address.tx_table.output')" />
                    </div>

                    <tx-msg class="tx-page-msg-details"
                        v-bind:source="addressBook[msg.source]?.user_friendly || null"
                        v-bind:destination="addressBook[msg.destination]?.user_friendly || null"
                        v-bind:value="msg.value"
                        v-bind:fwdFee="msg.fwd_fee"
                        v-bind:ihrFee="msg.ihr_fee"
                        v-bind:createdLt="msg.created_lt"
                        v-bind:hash="msg.hash"
                        v-bind:comment="msg.message_content?.decoded?.comment"
                        v-bind:op="msg.opcode" />
                </div>
            </div>
        </div> -->

    </section>
</template>

<script>
import IconTxTypeOrdinary from '@primer/octicons/build/svg/git-commit-24.svg?inline';
import IconTrace from '@primer/octicons/build/svg/note-24.svg?inline';
import { goToDevExplorerMixin } from '~/mixins';
import { hexToBase64, toBase64Web, base64ToHex } from '~/utils.js';
import { getTransactionByHashOrInMessageHash, getTransactionByInMsgHash } from '~/api';
import IconExpand from '@img/icons/tonscan/chevron-bottom-14.svg?vue';
import TxMsg from './TxMsg.vue';
import Diagram from './Diagram/Diagram.vue';
import Transaction from './Transaction/Transaction.vue';
import { getTransactionTraceV3 } from '../../api/toncenterV3';
import { formatTimestamp } from '../../helpers';
import Status from './Tabs/Status.vue';

export default {
    props: {
        hash: String,
    },

    data() {
        return {
            address: undefined,
            addressBook: undefined,
            type: undefined,
            isSuccess: undefined,
            exitCode: undefined,
            computeExitCode: undefined,
            actionResultCode: undefined,
            computeVmSteps: undefined,
            fee: undefined,
            storageFee: undefined,
            otherFee: undefined,
            timestamp: undefined,
            isLoading: true,
            isError: false,
            hashBase64: undefined,
            hashHex: undefined,
            lt: undefined,
            outMsgs: [],
            inMsg: undefined,
            trace: {},
            connections: [],
            msgOpen: false,
            tab: 'account',
            transaction: null,
            transactionData: null,
            timeStart: null,
            traceId: null,
            redirect: false,
            firstTransaction: null,
            showButton: false,
        };
    },

    computed: {
        isGettingByMsgHash() {
            return this.$route.name === 'tx_by_msg_hash';
        },

        devExplorerUrl() {
            return `/transaction?account=${this.address}&lt=${this.lt}&hash=${this.hashHex}`;
        },

        formattedDate() {
            return formatTimestamp(this.timeStart);
        },
    },

    created() {
        // redirect hex-formatted hash to base64-formatted:
        if (this.$route.params.hash.length === 64) {
            this.$router.replace(this.$localizeRoute({
                name: this.$route.name,
                params: {
                    hash: toBase64Web(hexToBase64(this.$route.params.hash)),
                },
            }));
            this.redirect = true;
            return;
        }

        this.loadData();
        this.loadTrace();
    },

    watch: {
        $route: 'loadData',
        transaction(newTransaction) {
            if (newTransaction !== null) {
                this.loadTransaction();
            }
        },
        redirect(newVal, oldVal) {
            this.loadTrace(newVal, oldVal);
        }
    },

    methods: {
        async loadData() {
            this.isLoading = true;
            this.isError = false;

            // both hash and in_msg_hash pages are served with this component:
            const apiMethod = this.isGettingByMsgHash
                ? getTransactionByInMsgHash
                : getTransactionByHashOrInMessageHash;

            try {
                const txRequest = await apiMethod(this.hash);
                const tx = txRequest.transactions[0];
                this.addressBook = txRequest.address_book;
                this.address = this.addressBook[txRequest.transactions[0].account].user_friendly;

                // in_msg hash matches the requested hash, meaning that we're searching
                // for in_msg_hash on tx page, need to replace URL:

                if (tx?.in_msg?.hash && toBase64Web(tx.in_msg.hash) === toBase64Web(this.hash)) {
                    this.$router.replace(this.$localizeRoute({
                        name: 'tx_by_msg_hash',
                        params: { hash: toBase64Web(this.hash) },
                    }));
                }

                // both phases are skipped if we're activating the new wallet, we should consider this tx successful:
                const newWalletTxSuccess = tx.description?.action?.result_code === undefined && tx.description?.compute_ph?.exit_code === undefined;
                const executionSuccess = tx.description.action?.action_result_code !== null && parseInt(tx.description?.action?.result_code, 10) <= 1;

                this.isSuccess = newWalletTxSuccess || executionSuccess;
                this.exitCode = this.isSuccess
                    ? tx.description?.action?.result_code
                    : tx.description?.compute_ph?.exit_code;

                this.computeExitCode = tx.description?.compute_ph?.exit_code || null;
                this.actionResultCode = tx.description?.action?.result_code || null;
                this.computeVmSteps = tx.description?.compute_ph?.vm_steps;

                this.fee = tx.total_fees;
                this.storageFee = tx.description?.storage_ph?.storage_fees_collected || 0;
                this.otherFee = tx.total_fees - this.storageFee || 0;
                this.timestamp = parseInt(tx.now + '000', 10);
                this.outMsgs = tx.out_msgs.map(Object.freeze);
                this.inMsg = tx.in_msg
                    ? Object.freeze(tx.in_msg)
                    : undefined;

                this.hashHex = base64ToHex(tx.hash);
                this.hashBase64 = tx.hash;
                this.lt = tx.lt;
            } catch (e) {
                this.isError = true;
                console.error(e);
            }

            this.isLoading = false;
        },

        async loadTrace() {
            try {
                const newHash = base64ToHex(this.hash);
                const res = await getTransactionTraceV3(newHash);
                this.trace = res.trace;
                this.connections = res.connections;

                this.firstTransaction = res.trace.transaction.hash;

                this.timeStart = res.series.timeStart * 1000;
                this.traceId = res.series.traceId;
            } catch (e) {
                console.error(e);
            }
        },

        async loadTransaction() {
            try {
                const searchHash = this.transaction;

                function findTransaction(trace, targetHash) {
                    if (trace.transaction.hash === targetHash) {
                        return trace.transaction;
                    }

                    if (trace.children && trace.children.length > 0) {
                        for (const child of trace.children) {
                            const result = findTransaction(child, targetHash);
                            if (result) {
                                return result;
                            }
                        }
                    }

                    return null;
                }

                const resultTransaction = findTransaction(this.trace, searchHash);

                if (resultTransaction) {
                    this.transactionData = resultTransaction;
                    console.log(this.transactionData);
                } else {
                    console.log('Transaction not found.');
                }
            } catch (e) {
                console.error(e);
            }
        },

        handleNodeClicked(data) {
            const hash = data.transaction.hash;
            this.transaction = hash;
            this.showButton = false;
        },

        handleButtonClicked() {
            this.showButton = false;
            this.transaction = this.firstTransaction;
        },

        toggleMsg() {
            this.msgOpen = !this.msgOpen;
        },
    },

    metaInfo() {
        return {
            title: this.$t('tx.meta.title', { hash: this.hash }),
            meta: [{
                property: 'robots',
                content: 'noindex',
            }],
        };
    },

    components: {
        TxMsg, Diagram, IconExpand, Status, IconTxTypeOrdinary, Transaction, IconTrace,
    },

    mixins: [goToDevExplorerMixin],
};
</script>

<style lang="scss">
.tx-status {
    display: flex;
    align-items: center;
    font-weight: 500;

    &--failed {
        color: var(--page-tx-status-error-color);
    }

    &--success {
        color: var(--page-tx-status-success-color);
    }

    &__icon {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: currentColor;
    }
}

.tx-flow-schematics {
    display: flex;
    align-items: center;
    border: 1px solid var(--page-tx-flow-diagram-border-color);
    overflow: hidden;
    border-radius: 8px;
}

.tx-flow-schematics-step {
    display: flex;
    align-items: center;

    &__inner {
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
    }

    &__phase {
        font-size: 12px;
    }

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-color: var(--page-tx-flow-diagram-border-color);
        border-width: 0 1px 1px 0;
        border-style: solid;
        transform: rotate(-45deg);
        margin: 0 8px 0 -28px;
        user-select: none;
        pointer-events: none;
    }

    &:last-child::after {
        display: none;
    }
}

.trace {
    margin: 0;
}

.card-timeago {
    color: var(--body-muted-text-color);
}

.trace {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.trace::-webkit-scrollbar {
  display: none;
}

.transaction-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    &__items {
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        border: 1px solid var(--body-light-muted-color);
        padding: 0 12px;
        line-height: 36px;
        border-radius: 7px;
        color: var(--body-text-color);
        transition: all 0.2s;
    }

    &__items:hover {
        border: 1px solid var(--body-muted-text-color);
        color: var(--body-text-color);
        text-decoration: none;
    }

    &__icon {
        width: 24px;
        color: var(--body-text-color);

        svg {
            display: block;
            fill: currentColor;
        }
    }

    &__value {
        color: var(--body-text-color);
        font-size: 14px;
        text-transform: uppercase;
    }
}

// .icon-fixed {
//     width: 21px!important;
//     padding: 0 2px;
// }
</style>
