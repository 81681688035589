<template>
    <section>
        <div class="indexpage-container">
            <div class="indexpage-main">
                <h1>
                    <span class="title-main">{{$t('indexpage.explore')}} </span>
                    <span class="title">The Open Network</span>
                </h1>
            </div>

            <app-search class="indexpage-search"
                focus-on-load
                show-search-button
                v-bind:placeholder="$t('indexpage.search_placeholder')"/>
        </div>

        <app-footer />
    </section>
</template>

<script>
import AppSearch from '~/components/search/AppSearch.vue';
import AppFooter from '~/components/AppFooter.vue';

export default {

    inject: ['openSettings'],

    metaInfo() {
        return {
            title: this.$t('indexpage.meta.title'),
            meta: [{
                property: 'description',
                content: this.$t('indexpage.meta.description'),
            }],
        };
    },

    components: {
        AppSearch,
        AppFooter,
    },
};
</script>

<style lang="scss">
.indexpage-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    min-height: calc(100vh - 165px);
}

.indexpage-mobile-container {
    display: flex;
    width: calc(100% + 16px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}

.indexpage-main h1 {
    font-size: 42px;
    margin-bottom: 40px;
}

.indexpage-main p {
    color: var(--body-muted-text-color);
    font-size: 16px;
    line-height: 1.7em;
    max-width: 480px;
    margin: 0 auto 30px;
}

.indexpage-main {
    text-align: center;
}

.indexpage-settings-button {
    position: absolute;
    top: 18px;
    right: 8px;
    opacity: .8;
    cursor: pointer;
    stroke: #797979;
    width: 18px;
    height: 18px;
    &:hover {
        opacity: 1;
    }
}

.indexpage-logo {
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto 24px;
}

.indexpage-widget {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.indexpage-widget-col .widget:last-child {
    margin-bottom: 0;
}

.indexpage-widget-col {
    width: calc(33.33333% - 14px);
}

.btn {
    width: 100%;
    padding: 10px;
    text-align: center;
    background: var(--button-widget);
    border-radius: 5px;
    cursor: pointer;
    color: var(--button-widget-text);
}

.btn:hover {
    background: var(--button-widget-hover);
}

.title {
    -webkit-text-fill-color: transparent;
    -webkit-font-feature-settings: "clig" off,"liga" off;
    font-feature-settings: "clig" off,"liga" off;
    background: linear-gradient(89deg,#2b82eb,#1cc8ff);
    background-clip: text;
    -webkit-background-clip: text;
    position: relative;
}

.blink {
    display: inline-block;
    height: 40px;
    width: 1px;
    margin-bottom: -4px;
    background: var(--blink-color);
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}

.btn {
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.widget-item {
    padding: 10px;
}

.widget {
    background-color: var(--card-background);
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    font-size: 14px;
    margin-bottom: 20px;
    min-width: 0;
    overflow: hidden;
    .widget-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 0 20px;
        grid-row: 1 / -1;
        grid-column: 1;
        min-width: 0;
    }
    .widget-content {
        grid-row: 1 / -1;
        grid-column: 1;
        min-width: 0;
    }
    .widget-title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-tab-color);
    }
    .widget-link {
        text-transform: uppercase;
        font-weight: 500;
    }
    .widget-link a:hover {
        text-decoration: none;
        color: var(--widget-view-all-btn-hover);
    }
    .widget-type {
        color: var(--body-muted-text-color);
        margin-bottom: 9px;
    }
    .widget-price {
        font-size: 23px;
    }
}

.widget-wide {
    grid-column: span 2;
}

@keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}

.indexpage-search {
    max-width: 600px;
    font-size: 16px;
    margin-bottom: 30px;
    .search-input {
        border-radius: 12px;
        border: 2px solid var(--card-border-color);
        box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
        background: var(--indexpage-search-background-color);
        &__input {
            padding: 12px 24px;
            background: transparent;
        }
    }

    .search-results {
        padding-bottom: 6px;
        &__item {
            margin-bottom: -2px;
            padding: 0 4px;
            &:first-child {
                margin-top: 4px;
            }
            &:last-child {
               margin-bottom: 0;
            }
        }
        &__link {
            border-radius: 8px;
        }
    }

    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
    .search-input__input {
        padding: 12px 26px;
    }
}

@media screen and (max-width: 1170px) {
    .widget {
        width: 100%;
        max-width: 600px;
        margin-left: calc((100% - 600px) / 2);
    }
    .indexpage-widget-col {
        width: calc(100%);
    }
}

@media screen and (max-width: 768px) {
    .indexpage-search {
        margin-bottom: 30px;
    }
    .widget-column {
        width: 50%;
    }
    .indexpage-main h1 {
        font-size: 34px;
        margin: 0 0 30px 0;
    }
    .blink {
        height: 26px;
    }
}

@media screen and (max-width: 600px) {
    .widget {
        width: 100%;
    }
    .title-main {
        display: block;
    }
    .indexpage-search {
        margin-bottom: 20px;
        .search-input__input {
            padding-left: 18px;
            padding-right: 18px;
        }

        .search-results {
            &__link {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
</style>
