<template>
    <header class="header"
        v-bind:class="{ 'header--mobile-search-visible': searchVisible, 'header-main': $route.name === 'index' }">

        <div class="header__container container">
            <ui-link v-bind:to="{ name: 'index' }" style="flex-grow: 0">
                <svg v-pre class="header__logo" fill="none" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path stroke="currentColor" stroke-width="2.5" d="M46.54 19.143c.608.424.92 2.325.605 6.494a42.027 42.027 0 0 1-.606 4.514c1.411 2.459 1.8 5.247 1.167 8.366-.561 2.761-2.398 5.341-5.345 7.805C39.413 48.787 35.995 51 31.969 51c-4.027 0-8.154-2.323-10.66-4.678-2.507-2.354-4.084-4.575-4.813-7.047-.883-2.997-.587-6.038.886-9.124-.313-1.654-.51-3.158-.59-4.514-.296-4.994-.106-5.965.59-6.494.947-.72 3.961 1.13 9.042 5.547 2.327-.421 4.186-.631 5.576-.631 1.39 0 3.228.21 5.513.63 5.114-4.326 8.123-6.175 9.026-5.546Z"/>
                    <path fill="currentColor" d="M31.932 43.822c-1.234.024-2.896-1.649-2.896-2.333 0-.685 1.948-.888 3.037-.888 1.09 0 2.897.116 2.897.888 0 .771-1.804 2.309-3.038 2.333ZM23.985 37.338c1.785.695 3.59.315 4.03-.85.44-1.165-.65-2.674-2.435-3.37-1.784-.695-3.305-.775-3.746.39-.44 1.165.367 3.134 2.151 3.83ZM39.96 37.137c-1.687.815-3.525.516-3.965-.65-.44-1.164.65-2.673 2.434-3.369 1.785-.695 3.127-.775 3.567.39.44 1.165-.349 2.813-2.036 3.629Z"/>
                </svg>
            </ui-link>

            <div class="header__links" v-if="!isMobile">
                <div class="header-link"
                    v-for="link in links"
                    v-bind:key="`header_link_${link}`"
                    v-bind:class="{ 'header-link--active': $route.name.startsWith(link) || (link === 'apps' && $route.name === 'app') }">
                    <ui-link class="header-link__anchor" v-bind:to="{ name: link }">
                        {{$t(`header.links.${link}`)}}
                    </ui-link>
                    <span class="header-link__line"/>
                </div>
            </div>

            <div class="header-ear-right">
                <app-search show-loopa class="header-search"
                    v-bind:placeholder="$t('header.search_placeholder')"
                    v-bind:focusInputField="searchVisible"
                    v-on:collapseMobileSearch="searchVisible = false"
                    v-show="$route.name != 'index'"
                />

                <nav class="header-ear-right__buttons">
                    <!-- We must stop click propagation, so that app-search global click handler
                         doesn't detect click on that icon. Otherwise the search field will collapse
                         instantly, because click on the icon is considered as outside click. -->
                    <icon-loopa
                        v-on:click.native.stop="/* <-- .stop must be here */ searchVisible = true"
                        v-if="$route.name != 'index'"
                        class="header-ear-right__button header-ear-right__button--loopa"/>

                    <div class="header-setting" v-on:click="openSettings" v-if="$route.name == 'index'">
                        <IconBurger v-if="isMobile" />
                        <svg v-else stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="3"/>
                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
                        </svg>
                    </div>

                    <div v-else v-on:click="openSettings">
                        <IconBurger v-if="isMobile" class="header-ear-right__button header-ear-right__button--burger" />
                        <icon-settings v-else class="header-ear-right__button header-ear-right__button--settings" />
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import IconLoopa from '@img/icons/material-duotone/search.svg?vue';
import IconSettings from '@img/icons/material-duotone/more_vert.svg?vue';
import IconBurger from '@img/icons/tonscan/burger.svg?vue';
import AppSearch from '~/components/search/AppSearch.vue';

export default {
    data() {
        return {
            searchVisible: false,
        };
    },

    computed: {
        links() {
            return [
                'blocks',
            ];
        },
    },

    inject: ['openSettings'],

    components: {
        IconLoopa, IconSettings, AppSearch, IconBurger,
    },
};
</script>

<style lang="scss">
.header {
    display: flex;
    background: var(--header-background);
    box-shadow: 0 1px 15px var(--header-box-shadow-color);
    height: 55px; // 54 + border
    border-bottom: 1px solid var(--header-border-bottom-color);
    box-sizing: border-box;
    position: sticky;
    margin-bottom: 18px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 80000;
    backdrop-filter: blur(20px);

    &__container {
        flex-grow: 1;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        width: 46px;
        height: 46px;
        margin: -10px 0;
        position: relative;
        bottom: -1px;
        color: var(--header-logo-color);
        transition: .1s color ease;
        &:hover {
            color: var(--body-text-color);
        }

        .header--mobile-search-visible & {
            display: none;
        }
    }

    &__links {
        height: 100%;
        margin-left: 20px;
        gap: 20px;
        display: flex;
    }
}

.header-link {
    user-select: none;
    height: 100%;
    position: relative;

    :hover {
        color: var(--body-text-color);
    }

    &__anchor {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        color: var(--body-muted-text-color);
        position: relative;
        text-decoration: none !important;
        height: 100%;
        transition: color .15s;
        .header-link--active & {
            color: var(--body-text-color);
        }
    }

    $app_header_bar_active_line_height: 3px;

    &__line {
        display: block;
        width: 0;
        height: $app_header_bar_active_line_height;
        background-color: var(--blue-bright);
        z-index: 999;
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        transform: translateY(-0.5px);
        //margin: (($app_header_bar_active_line_height * -1) - 1px) auto 0; // compensate 1px of bottom border
        border-radius: 2px 2px 0 0;
        transition: width .15s;
        .header-link--active & {
            width: 100%;
        }
    }
}

.header-main {
    background: transparent;
    border-bottom: 0;
    box-shadow: none;
    position: relative;
}
.header-setting {
    width: 24px;
    margin-left: 20px;
    cursor: pointer;
    color: var(--body-muted-text-color);
}
.header-setting svg {
    display: block;
}
// Prevent hover effect on small screens
// Because there's a bag when hover effect still apllies
// Even after menu was closed
@media screen and (min-width: 600px) {
    .header-setting:hover {
        color: var(--body-text-color);
    }
}
@media screen and (max-width: 600px) {
    .header-setting:active {
        color: var(--body-text-color);
    }
}
.header__links {
    display: flex;
    gap: 20px;
    height: 100%;
    margin-left: 15px;
    .header-link {
        transform: translateY(1.5px);
        user-select: none;
        text-transform: uppercase;
    }
    .header-link a {
        color: var(--body-muted-text-color);
        font-size: 14px;
        font-weight: 500;
        text-decoration: none!important;
    }
    .header-link a:hover {
        color: var(--body-text-color);
    }
    .header-link .router-link-active {
        color: var(--body-text-color);
    }
}
.header-burger-line,
.header-link a,
.header-setting {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.header-burger {
    width: 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    height: 24px;
    .header-burger-line {
        height: 1px;
        background: var(--body-text-color);
    }
    .header-burger-line:nth-child(1) {
        width: 80%;
    }
    .header-burger-line:nth-child(2) {
        width: 100%;
    }
    .header-burger-line:nth-child(3) {
        width: 40%;
    }
}
.header-burger:hover .header-burger-line {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .header__links {
        display: none;
    }
}

.header-ear-right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    &__buttons {
        display: flex;
        align-items: center;
    }
    &__button {
        cursor: pointer;
        color: #797979;
        padding: 18px 4px 16px 12px;
        margin: -12px -2px;
        fill: currentColor;
        width: 24px;
        height: 24px;
        &:hover {
            color: #444;
        }

        &--loopa {
            display: none;
            z-index: 80010;
        }
        &--settings, &--burger {
            transform: translateY(1px);
            z-index: 80001;
        }
    }
}

@media screen and (max-width: 600px) {
    .header-ear-right__button:hover {
        color: #797979;
    }
    .header-ear-right__button:active {
        color: #444;
    }
}

@media screen and (max-width: 1200px) {
    .header__container {
        padding: 0 8px;
    }
}

@media screen and (max-width: 768px) {
    .header__links {
        display: none;
    }
}

.header-search {
    position: relative;
    width: auto;
    font-size: 14px;

    .search-input {
        min-width: 340px;
        background: var(--body-light-muted-color);
        color: var(--body-text-color);
        border-radius: 12px;
        border: 2px solid transparent;
        transition: .12s min-width ease,
                    .12s border-color ease;
        &__loopa {
            margin: 0 -8px 0 8px;
            opacity: 0.3;
        }
        &__input {
            padding: 9px 0 9px 16px;
            &::placeholder {
                color: #939394;
            }
            &::-webkit-input-placeholder {
                color: #939394;
            }
        }
        &--expanded {
            min-width: 480px;
        }
        &:focus-within {
            min-width: 480px;
            outline: 0 none;
            background: transparent;
        }
    }
    .search-input-controls {
        &__loader {
            transform: scale(0.9);
        }
    }

    .search-results {
        padding-bottom: 4px;
        padding-top: 4px;
        overflow: hidden;
        &__link {
            margin: 0 0 0 0;
        }
    }
    .search-result {
        &__left {
            margin-left: -4px;
        }
    }
}

@media screen and (max-width: 480px) {
    .header {
        margin-bottom: 12px;
    }

    .header-search {
        flex-grow: 1;
        .search-input {
            width: 100%;
            max-width: 100%;
            min-width: 0;
            &:focus-within {
                max-width: 100%;
                width: 100%;
                min-width: 0;
            }
        }
    }

    // Small screen: hide search input UNLESS user clicked the loopa button:
    .header-search {
        display: none;

        .header--mobile-search-visible & {
            display: flex !important;
        }
    }

    .header-ear-right {
        // Small screen - hide right ear buttons IF the search field is active:
        &__buttons {
            .header--mobile-search-visible & {
                display: none;
            }
        }

        // Small screen - uncover loopa button:
        &__button {
            &--loopa {
                display: flex;
            }
        }
    }
}
</style>
