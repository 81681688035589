<template>
    <div class="tooltip" v-bind:style="{ display: isVisible ? 'block' : 'none', left: `${position.x}px`, top: `${position.y}px` }">
        <header class="chart-tooltip__header">{{ tooltipHeader }}</header>
        <table class="chart-tooltip__body">
            <tr v-for="(item, index) in tooltipItems" v-bind:key="index">
                <td class="chart-tooltip__line" v-bind:style="{ background: index === 0 ? 'rgb(108, 142, 117)' : 'rgba(108, 142, 117, 0.44)' }"></td>
                <td>{{ item.label }}:</td>
                <td>{{ item.value.toLocaleString('ru-RU', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) }} {{ item.suffix }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        tooltipHeader: String,
        tooltipItems: Array,
        position: Object,
        isVisible: Boolean,
    },
};
</script>

<style lang="scss">
.tooltip {
    display: none;
    background: var(--chart-tooltip-background);
    border-radius: 12px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, .35);
    color: var(--chart-tooltip-color);
    font-size: 13px;
    padding: 7px 10px;
    position: absolute;
    z-index: 99999;
    min-width: 200px;
    pointer-events: none;
}

.chart-tooltip {
    &__body {
        border-spacing: 4px;

        td {
            white-space: nowrap;
        }
    }
}
</style>
