<template>
    <div class="search">
        <div class="search-input" v-bind:class="{ 'search-input--error': isError, 'search-input--expanded': isExpanded }">
            <icon-loopa class="search-input__loopa" v-bind:class="{
                'search-input__loopa--hidden': !canDisplayLoopa,
            }"/>

            <input class="search-input__input" type="search" ref="search" enterkeyhint="search" spellcheck="false" autocomplete="off"
                v-bind:placeholder="placeholder"
                v-model.trim="searchValue"
                v-on:keyup.enter="handleEnterPress()"
                v-on:keyup.esc="handleEsc()"
                v-on:keydown.up="handleUp()"
                v-on:keydown.down="handleDown()"
                v-on:focus="handleFocus()"
                v-on:click="searchFieldClicked = true"
                v-on:blur="$emit('blur')">

            <aside class="search-input-controls">
                <svg v-show="addressLoading" class="search-input-controls__loader" xmlns="http://www.w3.org/2000/svg">
                    <circle v-pre cx="9" cy="9" fill="none" stroke="currentColor" stroke-width="2" r="8" stroke-dasharray="34 12">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 9 9;360 9 9" keyTimes="0;1"/>
                    </circle>
                </svg>

                <icon-close class="search-input-controls__close"
                    v-show="!addressLoading && searchValue"
                    v-on:click.native="searchValue = null"/>

                <button class="search-input-controls__go" v-if="showGoButton" v-on:click="search()">GO</button>
            </aside>
        </div>

        <div class="search-results" v-show="isExpanded">
            <ul class="search-results__list" ref="results">
                <li class="search-results__item"
                    v-for="(suggestion, idx) in suggestionList"
                    v-bind:key="suggestion.key"
                    v-bind:class="{ 'search-results__item--selected': idx === selectedItemIdx }"
                    v-bind:data-selected="idx === selectedItemIdx"
                    v-on:mouseenter="selectedItemIdx = undefined"> <!-- prefer hover over keyboard selected item -->
                    <!-- click event doesn't work well with <ui-link> and <router-link>: -->
                    <a class="search-results__link"
                        v-bind:href="$router.resolve(suggestion.routerParams).href"
                        v-on:click.prevent="navigateToSuggestion(suggestion)">

                        <!-- recent succesful search results has special simplified design: -->
                        <search-result-recent
                            v-if="suggestion.display_type === 'recent'"
                            v-on:delete="removeSuggestionFromRecents(suggestion)"
                            v-bind:addressType="suggestion.address_type"
                            v-bind:address="suggestion.address"
                            v-bind:name="suggestion.name"/>

                        <search-result
                            v-else-if="suggestion.display_type === 'dns_domain'">
                            <template v-slot:contents>
                                <result-domain
                                    v-bind:name="suggestion.name"
                                    v-bind:addressType="suggestion.address_type"/>
                            </template>
                        </search-result>

                        <search-result-app
                            v-else-if="suggestion.display_type === 'app_preview'"
                            v-bind:image="suggestion.image"
                            v-bind:name="suggestion.name"
                            v-bind:description="suggestion.description"
                            v-bind:badge="'application'"/>

                        <search-result-fancy
                            v-else
                            v-bind:image="suggestion.image"
                            v-bind:name="suggestion.name"
                            v-bind:addressType="suggestion.address_type"
                            v-bind:address="suggestion.address"/>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { matchInput, isValidDomain, isValidAddressCheap } from '~/search.js';
import { searchAddress } from '~/api/typesense.js';
import { showToast } from '~/toast.js';
import IconLoopa from '@img/icons/material-duotone/search.svg?inline';
import IconClose from '@img/icons/material-duotone/close.svg?inline';
import SearchResultApp from './AppSearchResultDapp.vue';
import SearchResultRecent from './AppSearchResultRecent.vue';
import ResultDomain from './AppSearchResultDomain.vue';
import SearchResult from './AppSearchResult.vue';
import SearchResultFancy from './AppSearchResultFancy.vue';

/* eslint camelcase: "off" */
class Suggestion {
    static SCHEMA_VERSION = 2;
    static DISPLAY_TYPE_RICH_PREVIEW = 'rich_preview';
    static DISPLAY_TYPE_DOMAIN = 'dns_domain';
    static DISPLAY_TYPE_RECENT = 'recent';
    static DISPLAY_TYPE_APP = 'app_preview';

    constructor({ key, address, routeParams, address_type, name, image, description, domain }, display_type) {
        this.key = key;
        this.address = address;
        this.address_type = address_type;
        this.name = name || undefined;
        this.image = image || undefined;
        this.description = description || undefined;
        this.domain = domain || undefined;
        this.routeParams = routeParams || {};
        this.display_type = display_type || this.DISPLAY_TYPE_RECENT;
    }

    get routerParams() {
        const typeToRouteNameMap = {
            nft: 'nft',
            pool: 'nominator',
            app: 'app',
        };

        const defaultRouteName = 'address';

        const routeParams = this.routeParams;

        if (this.address_type === 'nft') {
            routeParams.skeletonHint = 'collection';
        }

        return Object.freeze({
            name: typeToRouteNameMap[this.address_type] ?? defaultRouteName,
            params: routeParams,
        });
    }

    serialize() {
        return Object.freeze({
            _schema_version_: Suggestion.SCHEMA_VERSION,
            key: this.key,
            address: this.address,
            address_type: this.address_type,
            routeParams: this.routeParams,
            name: this.name,
            image: this.image,
            description: this.description,
        });
    }
}

export default {
    props: {
        showSearchButton: Boolean,
        focusOnLoad: Boolean,
        focusInputField: Boolean,
        placeholder: String,
        showLoopa: Boolean,
        maxSuggestedApps: {
            type: Number,
            default: 3,
        },
        maxSuggestedFancyAddresses: {
            type: Number,
            default: 3,
        },
        maxItems: {
            type: Number,
            default: 7,
        },
    },

    data() {
        return {
            searchValue: undefined,
            addressLoading: false,
            suggestions: [],
            selectedItemIdx: undefined,
            searchFieldClicked: false,
            searchFieldFocused: false,
            isError: false,
        };
    },

    computed: {
        isExpanded() {
            return this.suggestionList.length > 0;
        },

        canDisplayLoopa() {
            return this.showLoopa && (!this.searchValue || (!this.searchValue && this.suggestionList.length > 0));
        },

        showGoButton() {
            return this.showSearchButton
                && this.searchValue
                && (isValidDomain(this.searchValue) || isValidAddressCheap(this.searchValue));
        },

        recentAddresses() {
            return this.$store.state.searchRecentAddresses.map((suggestion) => { /* eslint arrow-body-style: "off" */
                return new Suggestion(suggestion, Suggestion.DISPLAY_TYPE_RECENT);
            });
        },

        suggestionList() {
            return this.searchFieldClicked && this.searchFieldFocused && !this.searchValue
                ? this.recentAddresses
                : this.suggestions;
        },
    },

    mounted() {
        document.addEventListener('click', this.handleClick, { passive: true });

        if (this.focusOnLoad) {
            this.$nextTick(() => this.$refs.search.focus());
        }
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    },

    watch: {
        $route() {
            this.exitSearchMode();
        },

        focusInputField(focusInput) {
            if (!focusInput) return;

            // requestAnimationFrame won't work because it thrashes user event,
            // which is necessary for safari to open the keyboard:
            this.$nextTick(() => this.$refs.search.focus());
            this.searchFieldClicked = true;
        },

        searchValue(newValue, oldValue) {
            if (!newValue || newValue.length < 3 || newValue === oldValue) {
                this.resetSuggestions();
            } else {
                this.getSuggestions();
            }
        },
    },

    methods: {
        exitSearchMode() {
            this.resetSuggestions();

            this.searchFieldFocused = false;
            this.searchValue = null;
            this.$refs.search.blur();

            this.$emit('collapseMobileSearch');
        },

        resetSuggestions() {
            this.suggestions = [];
            this.addressLoading = false;
            this.selectedItemIdx = undefined;
        },

        async getSuggestions() {
            if (!this.searchValue || this.searchValue.length < 3) {
                return;
            }

            this.addressLoading = true;

            const { domains } = await searchAddress(this.searchValue, {
                per_page: this.maxItems,
            });

            // const foundAddresses = [];

            // Enhanced address preview:
            // const suggestedFancyAddresses = addresses
            //     .filter(({ document: { address } }) => !foundAddresses.includes(address))
            //     .map(({ document: fancyAddress }) => {
            //         const displayType = Suggestion.DISPLAY_TYPE_RICH_PREVIEW;

            //         const suggestion = {
            //             key: `fancy_${fancyAddress.address}`,
            //             address: fancyAddress.address,
            //             address_type: fancyAddress.category,
            //             image: fancyAddress.image,
            //             name: fancyAddress.name,
            //             routeParams: {
            //                 address: fancyAddress.address,
            //             },
            //         };

            //         return new Suggestion(suggestion, displayType);
            //     });

            const suggestions = [];
            // .concat(suggestedFancyAddresses.slice(0, this.maxSuggestedFancyAddresses));

            // Append the search results with top domains if there are free slots:
            while (suggestions.length < this.maxItems && domains.length > 0) {
                const domain = domains.shift().document;

                const suggestion = {
                    key: `domain_${domain.address}`,
                    address_type: domain.type,
                    name: domain.domain,
                    routeParams: {
                        address: domain.resolved_address || domain.address,
                    },
                };

                suggestions.push(
                    new Suggestion(suggestion, Suggestion.DISPLAY_TYPE_DOMAIN),
                );
            }

            this.suggestions = suggestions.slice(0, this.maxItems).map(Object.freeze);
            this.addressLoading = false;
            this.selectedItemIdx = undefined;
        },

        async search() {
            this.addressLoading = true;
            const route = await matchInput(this.searchValue);
            this.addressLoading = false;

            if (route) {
                // Remember item only if it has an address (skip blocks, transactions, etc):
                if (route.params.address) {
                    this.navigateToSuggestion(
                        new Suggestion({
                            key: `address_${route.params.address}`,
                            address: route.params.address,
                            routeParams: route.params,
                        }),
                    );
                    return;
                }

                this.$router.push(this.$localizeRoute(route));
                this.exitSearchMode();
                return;
            }

            const errorMessage = isValidDomain(this.searchValue)
                ? this.$t('header.search_domain_error')
                : this.$t('header.search_address_error');

            showToast(errorMessage);

            this.isError = true;

            this.$refs.search.addEventListener('input', this.clearError);
            this.$refs.search.addEventListener('click', this.clearError);
        },

        clearError() {
            this.isError = false;
            this.$refs.search.removeEventListener('input', this.clearError);
            this.$refs.search.removeEventListener('click', this.clearError);
        },

        navigateToSuggestion(suggestion) {
            this.$router.push(
                this.$localizeRoute(suggestion.routerParams),
            );

            this.$store.dispatch('rememberRecentSearch', suggestion.serialize());

            this.exitSearchMode();
        },

        removeSuggestionFromRecents(suggestion) {
            this.$store.dispatch('forgetRecentSearch', suggestion.serialize());
        },

        handleEnterPress() {
            // If some item is selected with up/down keys:
            if (this.selectedItemIdx !== undefined) {
                this.navigateToSuggestion(
                    this.suggestionList.at(this.selectedItemIdx),
                );
            // Otherwise do the extended search (resolve domains, etc):
            } else {
                this.search();
            }
        },

        handleFocus() {
            this.searchFieldFocused = true;

            // if there's already some input in the field, show suggestions:
            this.getSuggestions();
        },

        handleClick(event) {
            // this handler watches for all document clicks,
            // so we do nothing if the search is not active:
            if (!this.$refs.search.contains(event.target)) {
                this.isError = false;
            }
            if (!this.isExpanded) {
                return;
            }

            const clickWasOutsideSearchBlock = !event.composedPath().includes(this.$el);

            // If clicked outside the active search box:
            //   1) close suggestion list
            //   2) blur the input field, but keep the input
            if (this.searchFieldFocused && clickWasOutsideSearchBlock) {
                this.resetSuggestions();
                this.searchFieldFocused = false;
                this.$emit('collapseMobileSearch');
            }
        },

        handleUp() {
            const reachedTop = this.selectedItemIdx === 0;

            // unset or reached top - skip to the end of the list:
            if (this.selectedItemIdx === undefined || reachedTop) {
                this.selectedItemIdx = this.suggestionList.length - 1;

            // otherwise select the previous item:
            } else {
                this.selectedItemIdx -= 1;
            }

            this.$nextTick(() => this.scrollSearchResults());
        },

        handleDown() {
            // if input field is already focused on component load
            // and user presses down button - allow showing suggestions:
            if (this.focusOnLoad && !this.searchFieldClicked) {
                this.searchFieldClicked = true;
            }

            const reachedBottom = this.selectedItemIdx >= this.suggestionList.length - 1;

            // unset or reached bottom - start from the beginning:
            if (this.selectedItemIdx === undefined || reachedBottom) {
                this.selectedItemIdx = 0;

            // otherwise select the next item:
            } else {
                this.selectedItemIdx += 1;
            }

            this.$nextTick(() => this.scrollSearchResults());
        },

        handleEsc() {
            // on the first press just clear the input:
            if (this.searchValue) {
                this.searchValue = null;

            // after the field is empty, exit the search box:
            } else {
                this.exitSearchMode();
            }
        },

        scrollSearchResults() {
            try {
                this.$refs.results.querySelector('[data-selected="true"]').scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            } catch {
                // just ignore unsupported browsers
            }
        },
    },

    components: {
        IconLoopa,
        IconClose,
        SearchResultApp,
        SearchResultFancy,
        SearchResultRecent,
        ResultDomain,
        SearchResult,
    },
};
</script>

<style lang="scss">
.search {
    width: 100%;
    position: relative;
}

.search-input {
    display: flex;
    align-items: stretch;
    width: 100%;
    border: none;
    color: var(--body-text-color);
    box-sizing: border-box;
    position: relative;
    z-index: 200;

    &--expanded {
        background: var(--indexpage-search-background-color) !important;
        border: 2px solid var(--card-border-color) !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &:focus-within {
        border-color: #2575ed !important;
    }

    &--error:focus-within {
        border: 2px solid !important;
        border-color: var(--red-bright) !important;
        animation: 0.4s shake linear;
    }

    &__loopa {
        width: 20px;
        height: 20px;
        opacity: .35;
        z-index: 100;
        align-self: center;
        flex-shrink: 0;
        transition: .1s all ease;
        overflow: hidden;
        fill: currentColor;
        &--hidden {
            width: 0;
        }
    }

    &__input {
        font-size: 1em;
        appearance: none;
        border: none;
        display: block;
        padding: 0;
        width: 100%;
        background: transparent;
        text-overflow: ellipsis;
        color: inherit;
        &::placeholder {
            color: #777;
        }
        &::-webkit-input-placeholder {
            color: #777;
        }
    }
}

.search-input-controls {
    display: flex;
    align-items: center;
    min-height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 0.25em 0.65em;
    gap: 0.5em;
    &__loader {
        width: 18px;
        height: 18px;
        z-index: 900;
    }
    &__go {
        border-radius: 9px;
        background: #2575ed;
        color: #FFF;
        height: 100%;
        min-width: 64px;
        font-size: 16px;
        cursor: pointer;
        margin-right: -0.4em; // make right offset from container border the same with top and bottom offset
    }
    &__close {
        width: 1.625em;
        height: 1.625em;
        fill: currentColor;
        opacity: .7;
        padding: 8px;
        margin: -8px;
        cursor: pointer;
        transition: .1s opacity ease;
        &:hover {
            opacity: 1;
        }
    }
}

@keyframes shake {
    0% {
        left:-5px;
    }
    16% {
        left:4px;
    }
    33% {
        left:-3px;
    }
    50% {
        left:2px;
    }
    66% {
        left:-2px;
    }
    83% {
        left:1px;
    }
    100% {
        left: 0px;
    }
}
</style>
