<template>
    <section>
        <div class="block-info-section">
            <div class="card information-block">
                <div class="chart-box">
                    <header class="chart-box__header">Election id</header>
                    <div class="chart-box__value">
                        <span class="skeleton" v-if="!stats">1234567890</span>
                        <span v-else>
                            {{ stats.cycleId }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="card information-block">
                <div class="chart-box">
                    <header class="chart-box__header">{{ $t('stats.validators_amount') }}</header>
                    <div class="chart-box__value">
                        <span class="skeleton" v-if="!validators">1234567890</span>
                        <span v-else>
                            {{ validators.length }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="card information-block">
                <div class="chart-box">
                    <header class="chart-box__header">{{ $t('stats.total_staked') }}</header>
                    <div class="chart-box__value">
                        <span class="skeleton" v-if="!totalStakedTon">1234567890</span>
                        <template v-else>
                            <icon-ton class="ton-icon" />
                            <span>{{ totalStakedTon }}</span>
                        </template>
                    </div>
                </div>
            </div>

            <div class="card information-block">
                <div class="chart-box">
                    <header class="chart-box__header">{{ $t('stats.staking_apy') }}</header>
                    <div class="chart-box__value">
                        <span class="skeleton" v-if="!stakingApy">1234567890</span>
                        <span v-else>
                            {{ stakingApy }}%
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <section class="card">
            <div class="tx-history-wrap desktop-table">
                <table class="ui-table validators-table">
                    <!-- Mobile table: -->
                    <template v-if="isMobile">
                        <thead>
                            <tr>
                                <th v-text="$t('common.address')" />
                                <th v-text="$t('stats.total_stake')" />
                            </tr>
                        </thead>

                        <tbody v-if="validators === undefined">
                            <tr v-for="i in 20" v-bind:key="`mobile_skeleton_${i}`">
                                <td><span class="skeleton">Ef9uLbpVmmuRjPODCtRTsu6U0QCw6irUd4NgsepBa0N_4BPv</span></td>
                                <td><span class="skeleton">123456789</span></td>
                            </tr>
                        </tbody>

                        <tbody v-else>
                            <tr v-for="validator in validators" v-bind:key="`mobile_row_${validator.index}`">
                                <td>
                                    <ui-address v-bind:address="validator.wallet_address" />
                                </td>
                                <td>
                                    {{ $ton(validator.stake) }} <span class="muted">TON</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                    <!-- Desktop table: -->
                    <template v-else>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th v-text="$t('common.address')" />
                                <th>ADNL</th>
                                <th v-text="$t('stats.total_stake')" />
                                <th v-text="$t('stats.frozen_stake')" />
                                <th v-text="$t('stats.weight')" />
                            </tr>
                        </thead>

                        <tbody v-if="validators === undefined">
                            <tr v-for="n in 20" v-bind:key="`desktop_skeleton_${n}`">
                                <td><span class="skeleton ">123</span></td>
                                <td><span class="skeleton">Ef9uLbpVmmuRjPODCtRTsu6U0QCw6irUd4NgsepBa0N_4BPv</span></td>
                                <td><span class="skeleton">12345...12345</span></td>
                                <td><span class="skeleton">123456789</span></td>
                                <td><span class="skeleton">123456789</span></td>
                                <td><span class="skeleton">100%</span></td>
                            </tr>
                        </tbody>

                        <tbody v-else>
                            <tr v-for="validator in validators" v-bind:key="validator.index">
                                <td class="muted">
                                    {{ validator.index + 1 }}
                                </td>

                                <td>
                                    <ui-address hide-name v-bind:address="validator.wallet_address" />
                                </td>

                                <td class="adnl-cell">
                                    {{ validator.adnl_addr.substring(0, 5) }}…{{ validator.adnl_addr.substring(59) }}

                                    <ui-copy-button class="copy-adnl-icon"
                                        v-bind:successMessage="$t('address.info.copy_success')"
                                        v-bind:copy="validator.adnl_addr" />
                                </td>

                                <td>
                                    {{ $ton(validator.stake) }} <span class="muted">TON</span>
                                </td>

                                <td class="muted">
                                    <template v-if="validator.frozenStake">
                                        {{ $ton(validator.frozenStake) }} TON
                                    </template>
                                    <template v-else>
                                        None
                                    </template>
                                </td>

                                <td>
                                    {{ validator.weight }}%
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </div>
        </section>
    </section>
</template>

<script>
import IconTon from '@img/icons/tonscan/ton-24.svg?vue';
import { getStakingInformation } from '~/api/tontech.js';
import { getAddressInfo } from '~/api/toncenter.js';
import { getValidatorsStats } from '~/api/elections.js';
import { MULTIPLIER } from '~/helpers.js';
import { prefixNumber } from '~/lib/Chart.js/helpers.js';

export default {
    data() {
        return {
            stakingApy: undefined,
            totalStakedTon: undefined,
            validators: undefined,
            stats: undefined,
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        async getData() {
            const [
                electorInfo,
                validationCycles,
                stakingInfo,
            ] = await Promise.all([
                getAddressInfo('Ef8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0vF'),
                getValidatorsStats({ limit: 2, withValidators: true }), // Last 2 validation cycles (second one is for 'Frozen stake')
                getStakingInformation(),
            ]);

            const totalStakedNanoTons = electorInfo.balance;

            const currentCycle = validationCycles[0];
            const previousCycle = validationCycles[1];

            const totalStake = currentCycle.validators.reduce((acc, { stake }) => acc + stake, 0);

            currentCycle.validators.forEach((validator) => {
                // Find the same validator in prevous cycle:
                const previousValidator = previousCycle.validators.find((oldValidator) => { // eslint-disable-line arrow-body-style
                    return oldValidator.adnl_addr === validator.adnl_addr;
                });

                /* eslint-disable no-param-reassign */
                // Add its stake as 'Frozen stake' property to the current cycle:
                validator.frozenStake = previousValidator?.stake;
                validator.weight = ((100 * validator.stake) / totalStake).toFixed(2);
                validator.stake = Math.floor(validator.stake / MULTIPLIER) * MULTIPLIER;
                /* eslint-enable no-param-reassign */
            });

            this.validators = currentCycle.validators;
            this.totalStakedTon = prefixNumber(totalStakedNanoTons / MULTIPLIER);
            this.stats = this.makeValidationStats(currentCycle);

            const medianApy = stakingInfo
                .toSorted((a, b) => Math.sign(b.annual_percent_yld - a.annual_percent_yld))
                .at(Math.round(stakingInfo.length / 2))
                .annual_percent_yld;

            this.stakingApy = (medianApy * 100).toFixed(1);
        },

        makeValidationStats(data) {
            return Object.freeze({
                cycleId: data.cycle_id,
                round: {
                    start: data.validation_cycle_start - 32768,
                    hold: data.validation_cycle_end + 32768,
                    end: data.validation_cycle_end,
                },
                validators: {
                    max: data.config16.max_validators,
                    min: data.config16.min_validators,
                    maxMain: data.config16.max_main_validators,
                },
                stake: {
                    min: data.config17.min_stake,
                    max: data.config17.max_stake,
                    maxFactor: data.config17.max_stake_factor,
                },
            });
        },
    },

    components: {
        IconTon,
    },
};
</script>

<style lang="scss">
.page-validators-info-card {
    margin-top: 0 !important;
    margin-bottom: 18px;
    flex-grow: 1;
}

.validators-table {
    thead {
        background-color: var(--tx-table-thead-alt-background);
    }

    th,
    td {
        padding: 10px 12px !important;
    }

    tr>th:first-child,
    tr>td:first-child {
        width: 26px;
    }

    tr>th:last-child,
    tr>td:last-child {
        width: 40px;
    }

    .copy-adnl-icon {
        opacity: 0;
        transition: .2s;
    }

    .adnl-cell:hover {
        cursor: default;

        .copy-adnl-icon {
            opacity: 1;
        }
    }
}</style>
