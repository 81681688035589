<template>
    <section>
        <section v-show="!wallet.invalid">
            <div class="page-nominator-top">
                <div class="card page-nominator-qr-card">
                    <ui-qr show-logo class="page-nominator-qr-card__qr-code" v-bind:value="lockerLink" v-bind:size="242"/>

                    <a class="bright-blue-button" style="padding: 8px 0;"
                        v-bind:href="lockerLink"
                        v-bind:class="{
                            'bright-blue-button--disabled': buttonDisabled,
                        }">
                        <template v-if="depositsUntil === undefined">
                            {{$t('nominator_pool.button_loading')}}
                        </template>

                        <template v-else-if="!buttonDisabled">
                            {{$t('nominator_pool.button_send_ton')}}
                        </template>

                        <template v-else>
                            {{$t('locker.button_text_disabled')}}
                        </template>
                    </a>

                    <p class="page-nominator-qr-card__text muted">
                        <template v-if="depositsUntil === undefined">
                            <span class="skeleton">send 50 tons or dont send what do i care</span>
                        </template>

                        <span v-else-if="!buttonDisabled"
                            v-html="$t('nominator_pool.help_text_add_stake', [$ton(50000000000)])"/>

                        <template v-else>
                            {{$t('locker.help_text_disabled')}}
                        </template>
                    </p>
                </div>

                <div class="card page-nominator-info-card">
                    <div class="card-title">
                        {{$t('locker.title')}}
                        <a class="card-title-help" target="_blank"
                            v-bind:href="$t('locker.title_help_link')">
                            <icon-title-help class="card-title-help__icon"/>
                            {{$t('common.what_is_this')}}
                        </a>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('address.info.address')"/>
                        <div class="card-row__value">
                            <ui-copy-button class="card-main-address"
                                v-bind:successMessage="$t('address.info.copy_success')"
                                v-bind:copy="address">
                                {{address}}
                            </ui-copy-button>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('address.info.balance')"/>
                        <div class="card-row__value" v-if="wallet.balance == '0' || wallet.balance">
                            {{$ton(wallet.balance)}} TON

                            <template v-if="wallet.balance != '0'">
                                <span v-if="$store.state.exchangeRate" style="color: #717579">
                                    ≈ <ui-fiat v-bind:tonValue="wallet.balance" />
                                </span>
                            </template>
                        </div>
                        <div v-else class="card-row__value">
                            <span class="skeleton">00000 TON ≈ 00000 USD</span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('address.info.last_activity')" />
                        <div class="card-row__value">
                            <span v-if="lastActivity === undefined" class="skeleton">99 minutes ago</span>
                            <span v-else-if="!lastActivity" v-text="this.$t('address.info.no_activity')" />
                            <ui-timeago v-else v-bind:timestamp="lastActivity" />
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('locker.storage')"/>
                        <div class="card-row__value card-row__value--flex-row">
                            <div class="card-row">
                                <div class="card-row__name" v-text="$t('locker.total_deposits')"/>
                                <div class="card-row__value">
                                    <span v-if="locker.total_coins_locked === undefined" class="skeleton">10000 TON</span>
                                    <template v-else>
                                        {{$ton(locker.total_coins_locked)}} TON
                                    </template>
                                </div>
                            </div>

                            <div class="card-row">
                                <div class="card-row__name" v-text="$t('locker.total_rewards')"/>
                                <div class="card-row__value">
                                    <span v-if="locker.total_reward === undefined" class="skeleton">10000 TON</span>
                                    <template v-else>
                                        {{$ton(locker.total_reward)}} TON
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('vesting.duration')"/>
                        <div class="card-row__value card-row__value--flex-row">
                            <div class="card-row">
                                <div class="card-row__name" v-text="$t('locker.deposits_until')"/>
                                <div class="card-row__value">
                                    <span v-if="depositsUntil === undefined" class="skeleton">January 20 at 18:40</span>
                                    <ui-datetime v-else hide-seconds v-bind:timestamp="depositsUntil"/>
                                </div>
                            </div>

                            <div class="card-row">
                                <div class="card-row__name" v-text="$t('vesting.start')"/>
                                <div class="card-row__value">
                                    <span v-if="vestingStart === undefined" class="skeleton">January 20 at 18:40</span>
                                    <ui-datetime v-else hide-seconds v-bind:timestamp="vestingStart"/>
                                </div>
                            </div>

                            <div class="card-row">
                                <div class="card-row__name" v-text="$t('vesting.vesting_duration')"/>
                                <div class="card-row__value">
                                    <span v-if="vestingDuration === undefined" class="skeleton">January 20 at 18:40</span>
                                    <ui-date-period v-bind:seconds="vestingDuration"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('locker.unlock')"/>
                        <div class="card-row__value">
                            <span v-if="locker.unlock_period === undefined" class="skeleton">Every n days after vesting period</span>
                            <i18n v-else path="locker.unlock_text">
                                <template v-slot:period>
                                    <ui-date-period v-bind:seconds="locker.unlock_period"/>
                                </template>
                            </i18n>
                        </div>
                    </div>
                </div>
            </div>

            <address-tabs style="margin-top: 18px;"
                v-bind:address="addressCanonical"
                v-bind:isActive="isActive"
                v-on:lastActivityUpdate="handleLastActivityUpdate"/>
        </section>

        <div style="display: none">
            <a ref="devExplorerLink" target="_blank" v-bind:href="devExplorerUrl">View in Toncoin Explorer</a>
        </div>
    </section>
</template>

<script>
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import { getAddressInfo, getLockerData } from '~/api';
import { canonizeAddress } from '~/tonweb.js';
import { goToDevExplorerMixin } from '~/mixins';
import { showToast } from '~/toast.js';
import UiQr from '~/components/UiQr.vue';
import AddressTabs from './AddressTabs.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            addressCanonical: undefined,
            wallet: {},
            locker: {},
            depositsUntil: undefined,
            vestingStart: undefined,
            vestingDuration: undefined,
            unlockPeriod: undefined,
            lastActivity: undefined,
        };
    },

    computed: {
        isActive() {
            if (this.wallet.is_active === undefined) {
                return undefined; // keep loading state
            }

            return this.wallet.is_active;
        },

        buttonDisabled() {
            return new Date().getTime() >= (this.depositsUntil ?? 0) * 1000;
        },

        lockerLink() {
            return this.buttonDisabled
                ? this.$t('locker.help_text_disabled')
                : `ton://transfer/${this.address}?text=d`;
        },
    },

    watch: {
        $route: 'loadData',
        address: {
            immediate: true,
            handler: 'canonizeAddress',
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        canonizeAddress() {
            this.addressCanonical = canonizeAddress(this.address);
        },

        async loadData() {
            this.wallet = await getAddressInfo(this.address);

            const { locker } = await getLockerData(this.address).catch((error) => {
                if (error.response.status === 400 || error.response.status === 500) {
                    showToast(this.$t('locker.error.redirect_invalid'));

                    this.$router.push(this.$localizeRoute({
                        name: 'address',
                        params: { address: this.address },
                    }));
                }
            });

            this.locker = locker;
            this.depositsUntil = locker.deposits_end_time;
            this.vestingStart = locker.vesting_start_time;
            this.vestingDuration = locker.vesting_total_duration;
        },

        handleLastActivityUpdate(timestamp) {
            this.lastActivity = timestamp;
        },
    },

    components: {
        UiQr, AddressTabs, IconTitleHelp,
    },

    mixins: [goToDevExplorerMixin],
};
</script>
