/**
 * @param  {Object} options.chart
 * @param  {Object} options.tooltip
 * @param  {HTMLElement} tooltipEl
 * @return {Object}
 */
const createTooltipObject = function createTooltipWithData({ chart, tooltip: tooltipModel }, tooltipEl) {
    // graph is not initialized yet, but mouse events are already registered:
    if (!tooltipModel.dataPoints) {
        return { visible: false, values: [] };
    }

    // don't show tooltip for skeletons:
    if (tooltipModel.dataPoints[0].dataset.isSkeleton) {
        return { visible: false, values: [] };
    }

    const tooltip = {
        visible: tooltipModel.opacity === 1,
        header: tooltipModel.dataPoints[0].label,
        vAlign: tooltipModel.yAlign,
        tickDiff: null,
        values: tooltipModel.dataPoints.map((dataPoint) => {
            // border color for line chart, background color for bar chart:
            const color = dataPoint.dataset.borderColor || dataPoint.dataset.backgroundColor;

            const tooltipColor = Array.isArray(color)
                ? color[dataPoint.dataIndex]
                : color;

            return Object.freeze({
                color: tooltipColor,
                value: dataPoint.formattedValue.replace(',', '.'),
                label: dataPoint.dataset.label,
                suffix: dataPoint.dataset.suffix,
            });
        }),
    };

    const labels = chart.config.data.labels;

    // replace serialized date values with date object:
    if (chart.scales.x?.type === 'time') {
        tooltip.header = labels.at(tooltipModel.dataPoints[0].dataIndex);
        tooltip.tickDiff = Math.round((labels[1] - labels[0]) / 1000);
    }

    const tooltipWidth = tooltipEl.offsetWidth || 0;
    const tooltipHeight = tooltipEl.offsetHeight || 0;

    let tooltipOffsetLeft = tooltipModel.caretX;
    let tooltipOffsetTop = tooltipModel.caretY;

    if (tooltipOffsetLeft > chart.chartArea.right - tooltipWidth) {
        tooltipOffsetLeft -= tooltipWidth;
    }

    if (tooltipOffsetTop > chart.chartArea.bottom - tooltipHeight) {
        tooltipOffsetTop -= tooltipHeight;
    }

    tooltip.left = Math.round(tooltipOffsetLeft + 20);
    tooltip.top = Math.round(tooltipOffsetTop - 10);

    return tooltip;
};

/**
 * @param  {Object} chartContext
 * @return {Object}
 */
export const createTooltipStub = function createEmptyTooltipObjectWithReactiveProperties(chartContext) {
    return {
        visible: false,
        header: chartContext.config.data.labels[0],
        vAlign: undefined,
        tickDiff: undefined,
        values: chartContext.config.data.datasets.map(dataset => ({
            value: dataset.data[0],
            color: dataset.backgroundColor,
            label: dataset.label,
        })),
    };
};

/**
 * @param  {HTMLElement} element
 * @param  {Function}    handleChange
 * @return {Object}
 */
export const createTooltip = ({ element, onChange: handleChange }) => ({
    mode: 'index',
    enabled: false,
    intersect: false,
    /* eslint no-underscore-dangle: "off" */
    external: (context) => {
        const tooltip = createTooltipObject(context, element);

        if (!tooltip.visible) {
            context.tooltip._timer = setTimeout(() => handleChange(tooltip), 300);
            return undefined;
        }

        clearTimeout(context.tooltip._timer);
        return handleChange(tooltip);
    },
});
