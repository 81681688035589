const trimSlashes = value => (value || '').replace(/\/+$/, '');

/* eslint-disable no-useless-escape, prefer-regex-literals, camelcase, no-undef */
export const ADDRESS_REGEX = new RegExp(/[UEk0][Qf][\w\-]{46}/);
export const TX_REGEX_OLD = new RegExp(/^\d+[$:](?<hash>[\w\+\-\/]{43}\=)[$:][\w\+\-\/]+$/, 'i');

// All "proccess_env_*" variables in this file are replaced to the literal values from process.env during build (see webpack.config.js):
export const APP_MAIN_LOCALE = process_env_APP_MAIN_LOCALE || 'en';
export const APP_MAIN_THEME = 'auto';
export const APP_MAIN_EXCHANGE_CURRENCY = 'usd';
export const APP_MAIN_TX_SOURCE = 'toncenter';

export const IS_TESTNET = process_env_IS_TESTNET === true;
export const ADDRBOOK_LOCATION = process_env_ADDRBOOK_LOCATION || 'https://address-book.tonscan.org/addresses.json';
export const COINGECKO_ENDPOINT = 'https://api.coingecko.com/api/v3';
export const DEV_EXPLORER_ADDRESS = process_env_DEV_EXPLORER_ADDRESS || 'https://explorer.toncoin.org';
export const TONCENTER_API_ENDPOINT = process_env_TONCENTER_API_ENDPOINT || 'https://toncenter.com/api/v2';
export const TONCENTER_V3_API_ENDPOINT = process_env_TONCENTER_V3_API_ENDPOINT || 'https://toncenter.com/api/v3';
export const TONCENTER_API_KEY = process_env_TONCENTER_API_KEY;
export const TONCENTER_INDEX_API_ENDPOINT = process_env_TONCENTER_INDEX_API_ENDPOINT || 'https://toncenter.com/api/index';
export const TONCENTER_INDEX_API_KEY = process_env_TONCENTER_INDEX_API_KEY || TONCENTER_API_KEY;
export const EXTENDER_CONTRACTS_API_ENDPOINT = process_env_EXTENDER_CONTRACTS_API_ENDPOINT || 'https://api.ton.cat/v2/contracts/';
export const TONCENTER_ELECTIONS_API_ENDPOINT = process_env_TONCENTER_ELECTIONS_API_ENDPOINT || 'https://elections.toncenter.com';
export const GETGEMS_GRAPHQL_ENDPOINT = process_env_GETGEMS_GRAPHQL_ENDPOINT || 'https://api.getgems.io/graphql';
export const TONAPI_ENDPOINT = process_env_TONAPI_ENDPOINT || 'https://tonapi.io/v2';
export const TONAPI_KEY = process_env_TONAPI_KEY;
export const TONTECH_API_ENDPOINT = process_env_TONTECH_API_ENDPOINT || 'https://api.ton.cat/tontech';

export const TYPESENSE_API_ENDPOINT = process_env_TYPESENSE_API_ENDPOINT || 'https://typesense.tonscan.org';
export const TYPESENSE_API_KEY = process_env_TYPESENSE_API_KEY;

export const JETTON_INDEXER_API_ENDPOINT = process_env_JETTON_INDEXER_API_ENDPOINT || 'https://jetton-index.tonscan.org/v2';
export const NEWS_API_ENDPOINT = process_env_NEWS_API_ENDPOINT || 'https://api.tlgrm.ru/v3/';

export const TONSCAN_APPS_IMAGE_CDN_ROOT = trimSlashes(process_env_TONSCAN_APPS_IMAGE_CDN_ROOT || 'https://i.tonscan.org/apps/');

export const BLOCKCHAIN_CONFIG_ENDPOINT = process_env_BLOCKCHAIN_CONFIG_ENDPOINT || 'https://jetton-index.tonscan.org/config';
