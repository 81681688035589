import { getCSSVar } from '~/utils.js';
import $bus from '~/eventBus.js';

/**
 * @return {Object}
 */
export const getChartColors = () => ({
    gridColor: getCSSVar('chart-grid-color'),
    lineColor: getCSSVar('chart-line-green-color'),
    skeletonColor: getCSSVar('chart-skeleton-background-color'),
    barPrimaryColor: getCSSVar('chart-bar-primary-color'),
    barSecondaryColor: getCSSVar('chart-bar-secondary-color'),
    pieBorderColor: getCSSVar('chart-pie-border-color'),
});

export default {
    data() {
        return {
            style: getChartColors(),
        };
    },

    // This is for AUTO mode when browser chooses a theme:
    created() {
        $bus.$on('browserColorSchemeChanged', this.updateColorSchemeOnThemeChange);
    },

    beforeDestroy() {
        $bus.$off('browserColorSchemeChanged', this.updateColorSchemeOnThemeChange);
    },

    // This is for manualy changing between DARK and LIGHT in settings modal:
    watch: {
        '$store.state.appTheme': {
            immediate: false,
            handler() {
                this.updateColorSchemeOnThemeChange();
            },
        },
    },

    methods: {
        updateColorSchemeOnThemeChange() {
            Object.assign(this.style, getChartColors());
            this.repaint();
        },

        // Usually all we need to do to change colors is simply redraw the component.
        // Otherwise override this method in the component (e.g. like in UiChartDonut).
        repaint() {
            this.$forceUpdate();
        },
    },
};
