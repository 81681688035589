<template>
    <div class="footer">
        <div class="footer-container">
            <div class="footer-nav">
                <a href="https://t.me/catchain" target="_blank" class="footer-nav-link">
                    <span>News</span>
                </a>
                <a href="https://t.me/faqbot" target="_blank" class="footer-nav-link">
                    <span>Report Issue</span>
                </a>
                <a href="https://github.com/catchain/tonscan" class="footer-nav-link">
                    <span>GitHub</span>
                </a>
                <a href="https://toncenter.com" class="footer-nav-link">
                    <span>API</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchVisible: false,
        };
    },
};
</script>

<style lang="scss">

.footer {
    padding: 25px 10px 0 10px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
}

.footer-container {
    flex-grow: 1;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-nav {
    display: flex;
    gap: 20px;
    height: 100%;
    a {
        color: var(--body-muted-text-color);
        font-size: 14px;
        text-decoration: none !important;
    }
    a:hover {
        color: var(--body-text-color);
    }
}

.footer-nav a {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.footer-copyright {
    color: var(--body-muted-text-color);
    font-size: 14px;
}

</style>
