<template>
    <section>
        <div class="block-info-section">
            <div
                class="card information-block"
                v-for="(item, idx) in informationBlocks"
                v-bind:key="`blocks_infoblock_${idx}`">
                <div class="chart-box">
                    <header class="chart-box__header" v-text="item.header"></header>
                    <div class="chart-box__value">
                        <template v-if="!item.value || item.value[0] == 0">
                            <span class="skeleton">1,234,567,890</span>
                        </template>
                        <template v-else-if="Number.isFinite(item.value)">
                            <ui-animated-number v-bind:value="item.value" v-bind:suffix="item.suffix"/>
                        </template>
                        <template v-else>
                            {{ item.value }}
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <workchain-blocks v-if="isWorkchainBlocksPage" v-bind:workchain="$route.query.wc" />

        <template v-else>
            <div class="card" style="margin-bottom: 18px;">
                <div class="card-title">{{ $t('address.all_tx_table.title') }}</div>
                <section class="block-recent-tx-section">
                    <transactions-table />
                </section>
            </div>

            <section class="block-chains-section">
                <div class="card">
                    <page-blocks-mini-table v-bind:blocks="masterchainBlocks" title="masterchain" />
                </div>
                <div class="card">
                    <page-blocks-mini-table v-bind:blocks="workchainBlocks" title="workchain" />
                </div>
            </section>
        </template>
    </section>
</template>

<script>
import { blockAnal } from '~/api/extenderContracts.js';
import { getPreviousBlocks } from '~/api';
import UiAnimatedNumber from '~/components/UiAnimatedNumber.vue';
import PageBlocksMiniTable from './PageBlocksMiniTable.vue';
import WorkchainBlocks from './PageBlocksWorkchainBlockTable.vue';
import TransactionsTable from './PageBlocksTransactions.vue';

const formatter = new Intl.NumberFormat('en');

export default {
    data() {
        return {
            blockAnal: null,
            lastBlockTime: 0,
            blocks: [],
            isLoading: false,
            mcHeight: null,
            totalTx: null,
            averageTps: 0,
            timerHandle: null,
        };
    },

    computed: {
        isWorkchainBlocksPage() {
            return !Number.isNaN(Number(this.$route.query.wc));
        },

        masterchainBlocks() {
            return this.blocks.filter(b => b.workchain === -1).slice(0, 15);
        },

        workchainBlocks() {
            return this.blocks.filter(b => b.workchain === 0).slice(0, 15);
        },

        shouldHandleScroll() {
            return !this.isLoading;
        },

        informationBlocks() {
            return [
                {
                    header: this.$t('stats.masterchain_height'),
                    value: this.mcHeight,
                },
                {
                    header: this.$t('blocks.block_time'),
                    value: this.$tc('common.seconds', this.lastBlockTime),
                },
                {
                    header: this.$t('stats.transaction_count'),
                    value: this.totalTx,
                },
                {
                    header: this.$t('blocks.tx_per_second'),
                    value: `${this.averageTps.toFixed(2)} TPS`,
                },
            ];
        },
    },

    async created() {
        this.initializeTotalTx();

        await Promise.all([
            this.loadPreviousBlocks(),
            this.blockAnalytics(),
        ]);

        this.timerHandle = setInterval(this.loadPreviousBlocks, 6000);
    },

    beforeDestroy() {
        clearInterval(this.timerHandle);
    },

    methods: {
        initializeTotalTx() {
            try {
                const storedTotalTx = localStorage.getItem('totalTx');
                this.totalTx = storedTotalTx ? JSON.parse(storedTotalTx) : null;
            } catch (error) {
                console.log('Failed to parse totalTx from localStorage', error);
                this.totalTx = null;
            }
        },

        async loadPreviousBlocks() {
            const takeCount = 80;

            try {
                this.isLoading = true;
                const blocks = await getPreviousBlocks({ limit: takeCount });
                this.blocks = blocks;
                if (blocks.length === 0) return;

                this.mcHeight = formatter.format(blocks[0].seqno);
                this.lastBlockTime = blocks[0].gen_utime - blocks[1].gen_utime;
                this.averageTps = blocks.reduce((acc, { tx_count }) => acc + tx_count, 0) / takeCount;

                if (Number.isInteger(this.totalTx)) {
                    this.totalTx += this.masterchainBlocks[0]?.tx_count || 0;
                    this.totalTx += this.workchainBlocks[0]?.tx_count || 0;
                }

                // Обновляю localstorage
                localStorage.setItem('totalTx', JSON.stringify(this.totalTx));
            } catch (error) {
                console.log('Error loading previous blocks:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async blockAnalytics() {
            try {
                const analytics = await blockAnal();
                this.blockAnal = analytics;
                this.totalTx = formatter.format(analytics.trans_ord_count);

                // Обновляю localstorage
                localStorage.setItem('totalTx', JSON.stringify(this.totalTx));
            } catch (error) {
                console.log('Error fetching block analytics:', error);
            }
        },
    },

    components: {
        UiAnimatedNumber,
        PageBlocksMiniTable,
        WorkchainBlocks,
        TransactionsTable,
    },
};
</script>

<style lang="scss">
.block-info-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 82px;
    grid-gap: 14px;
    margin-bottom: 18px;
    .information-block {
        margin-top: 0 !important;
    }
}

.block-recent-tx-section {
    height: 356px;
    overflow: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        display: none;
    }
}

.block-chains-section {
    display: grid;
    width: 100%;
    align-items: stretch;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .card + .card {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {
    .block-info-section {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-chains-section {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 599px) {
    .block-info-section {
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin-bottom: 14px;

        .information-block {
            border-radius: 0;
            border-bottom-width: 0px;
            &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                border-bottom-width: 1px;
            }
        }

        .chart-box {
            &__value {
                font-size: 24px;
            }
        }
    }
}
</style>
