<template>
    <div class="search-result"
        v-bind:class="{ 'search-result--compact': compact }">

        <aside v-if="showAvatar" class="search-result__left">
            <div class="search-result__address-icon">
                <icon-ton class="search-result__address-icon__svg"/>
            </div>

            <img class="search-result__image"
                v-if="avatarUrl"
                v-bind:src="avatarUrl">
        </aside>

        <main class="search-result__right">
            <div v-if="name" class="search-results__name">
                <span class="search-results__name__title" v-text="name"/>
                <slot name="nameSuffix"/>
            </div>

            <slot name="contents"/>
        </main>

        <aside v-if="$slots.buttons" class="search-result__buttons">
            <slot name="buttons"/>
        </aside>
    </div>
</template>

<script>
import IconTon from '@img/icons/tonscan/ton-24.svg?inline';

export default {
    props: {
        showAvatar: Boolean,
        avatarUrl: String,
        name: String,
        compact: Boolean,
        description: String,
    },

    components: {
        IconTon,
    },
};
</script>

<style lang="scss">
.search-results {
    z-index: 100;
    width: 100%;
    position: absolute;
    top: 100%;
    border: 2px solid var(--card-border-color);
    border-top: none;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    color: var(--body-text-color);
    box-sizing: border-box;
    font-size: 16px;
    background: var(--indexpage-search-background-color);
    border-radius: 0 0 12px 12px;
    &__list {
        overflow-y: auto;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__link {
        display: flex;
        flex-direction: column;
        padding: 12px 16px;
        line-height: 1.4;
        color: inherit;
        background: transparent;
        &:hover, .search-results__item--selected & {
            background: rgba(142, 142, 142, 0.16);
            text-decoration: none;
        }
    }
    &__name {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        &__title {
            flex-shrink: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.search-result {
    display: flex;
    align-items: center;
    white-space: nowrap;
    &--compact {
        padding-left: 2px;
    }
    &__left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        position: relative;
        .search-result--compact & {
            display: none;
        }
    }
    &__address-icon {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        background: var(--card-border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &__svg {
            width: 24px;
            height: 24px;
            fill: currentColor;
            margin-right: -1px;
        }
        .search-result--compact & {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            box-shadow: 0 0 0 4px #1d1d1d;
            &__svg {
                width: 60%;
                height: 60%;
                margin-bottom: -1px;
            }
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &__right {
        overflow: hidden;
        .search-result--compact & {
            display: flex;
            align-items: center;
        }
    }
    &__buttons {
        flex-grow: 0;
        display: flex;
        align-items: center;
        align-self: center;
        margin-right: -4px;
        padding-left: 18px;
        margin-left: auto;
        z-index: 99999;
        position: relative;
    }
}
</style>
