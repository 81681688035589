<template>
    <section>
        <div v-if="!showSkeleton && holders.length === 0">
            <div class="tx-history-empty-panel">
                {{$t('jetton.no_holders')}}
            </div>
        </div>

        <div v-else class="tx-history-wrap">
            <table class="ui-table">
                <thead>
                    <tr>
                        <th v-text="$t('common.address')"/>
                        <th v-text="$t('address.tokens.table_token_balance')"/>
                        <th class="ui-table__cell--align-right" v-text="$t('common.pie')"/>
                    </tr>
                </thead>

                <tbody v-if="showSkeleton">
                    <tr v-for="i in 5" v-bind:key="`jetton_tab_holders_skeleton_${i}`">
                        <td v-pre>
                            <span class="skeleton">SomeCoolBouncableAddress</span>
                        </td>
                        <td v-pre>
                            <span class="skeleton">1000000</span>
                        </td>
                        <td v-pre class="ui-table__cell--align-right">
                            <span class="skeleton">100.00%</span>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr v-for="holder in holders" v-bind:key="`jetton_tab_holders_item_${address}_${holder.owner.address}`">
                        <td>
                            <ui-address
                                v-bind:address="holder.owner.address" />
                        </td>
                        <td>
                            {{$ton(holder.balance, decimals)}}
                            <span style="margin-left: 4px; opacity: 0.5;" v-text="symbol"/>
                        </td>
                        <td class="ui-table__cell--align-right">
                            {{ getPie(holder.balance) }}%
                        </td>
                    </tr>
                </tbody>
            </table>

            <ui-mugen-scroll
                v-bind:handler="loadData"
                v-bind:shouldHandle="shouldHandleScroll"
                v-bind:showButton="showPreloader"
                v-bind:isLoading="isLoading"/>
        </div>
    </section>
</template>

<script>
import { getJettonHoldersTonapi } from '~/api/tonapi.js';
import { canonizeAddress } from '~/tonweb.js';

export default {
    limit: 50,

    props: {
        address: String,
        decimals: Number,
        supply: String,
        symbol: String,
    },

    data() {
        return {
            holders: [],
            isLoading: true,
            hasMore: true,
            addressBook: {},
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.address
                && this.hasMore
                && this.holders.length > 0;
        },

        showPreloader() {
            return this.address
                && this.holders.length > 0
                && this.hasMore;
        },

        showSkeleton() {
            return this.isLoading
                && this.holders.length === 0;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            this.isLoading = true;

            getJettonHoldersTonapi(this.address, {
                limit: this.$options.limit,
                offset: this.holders.length,
            }).then((response) => {
                response.forEach(({ owner }) => {
                    owner.address = canonizeAddress(owner.address, { type: owner.is_wallet ? 'wallet' : undefined }); // eslint-disable-line
                });
                this.holders = this.holders.concat(Object.freeze(response));
                this.hasMore = response.length >= this.$options.limit;
                this.isLoading = false;
            });
        },

        getPie(balance) {
            let fixed = 2;
            const formula = balance / this.supply * 100; // eslint-disable-line no-mixed-operators
            // Show more digits on small balances:
            if (formula < 1) fixed = 6;
            return parseFloat(formula.toFixed(fixed));
        },
    },
};
</script>
